<template>
  <primary-template id="home">
    <section class="slider-area fix slider-bg" data-background="/assets/img/slider/slider_bg.png">
      <div class="container">
        <div class="row align-items-center justify-content-center align-content-center">
          <div class="col-lg-5 pt-0">
            <div class="slider-content">
              <h2 class="mb-0">HCStoreRP</h2>
              <h3 class="mb-0">A melhor solução para o seu servidor!</h3>
              <p>Tenha o controle de todos os seus ganhos com muita facilidade no painel. Monitore seus ganhos analisando intervalos de tempo mensais ou diários.</p>
              <div class="slider-btn">
                <a href="" @click.prevent="scrollToSection('plans')" class="btn">Testar agora</a>
                <a href="" @click.prevent="scrollToSection('show-room')" class="btn transparent-btn">Saiba mais</a>
              </div>
            </div>
          </div>
          <div class="col-lg-7 pt-100">
            <img src="/assets/img/img-header.png" class="img-fluid" alt="">
          </div>
        </div>
      </div>
    </section>
    <features-area id="features-area"></features-area>

    <show-room id="show-room"></show-room>

    <templates id="templates"></templates>

    <plans id="plans"></plans>

    <search-domain id="search-domain"></search-domain>

    <unlimited-features id="unlimited-features"></unlimited-features>

    <faq id="faq"></faq>
  </primary-template>
</template>

<script>
import primaryTemplate from '@/templates/primaryTemplate'
import featuresArea from '@/components/features'
import showRoom from '@/components/showroom'
import templates from '@/components/templates'
import searchDomain from '@/components/searchDomain'
import plans from '@/components/plans'
import unlimitedFeatures from '@/components/unlimted-features'
import faq from '@/components/faq'

export default {
  name: 'Home',
  components: {
    primaryTemplate,
    featuresArea,
    showRoom,
    templates,
    searchDomain,
    plans,
    unlimitedFeatures,
    faq,
  },
  data () {
    return {

    }
  },
  methods: {
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({
          behavior: 'smooth'
        });
      }
    }
  },
}
</script>

<style scoped>

.slider-area.fix.slider-bg {
  background-repeat: no-repeat;
  background-size: cover;
  background-image: linear-gradient(109deg, rgb(66, 6, 76) 17%, rgb(32, 32, 32) 46%);
}

</style>