<template>
  <section class="hosting-plan-area p-relative pt-50">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-6 col-lg-8">
          <div class="section-title white-title text-center mb-75">
            <h2>Adquira já sua loja</h2>
            <p>Planos para você usar sem limites!</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4 col-md-4">
          <div class="single-hplan active text-center mb-30">
            <div class="hplan-head">
              <span>Gratuito</span>
              <h3>R$0 <small>/ mês</small></h3>
              <h6>Taxa de 7% em cada venda</h6>
            </div>
            <div class="hplan-body mb-40">
              <p>Para você que está começando seu servidor</p>
              <div class="hplan-icon w-100">
                <ul class="mb-10 mt-10 space-y-2 text-left">
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Edite as cores da sua loja</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Produtos ilimitados</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Categorias ilimitadas</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> 3 Sub-usuários</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Descontos ilimitados</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> MercadoPago</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Entregas instantâneas</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Sem limite de faturamento</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Integração com Discord</li>
                </ul>
              </div>
            </div>
            <div class="hplan-btn">
              <a href="https://cliente.hcrp.store/register/free" class="btn">Teste gratuitamente</a>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-4">
          <div class="single-hplan active text-center mb-30">
            <div class="hplan-head">
              <span>Básico</span>
              <h3>R$35 <small>/ mês</small></h3>
              <h6>Sem taxa sobre venda</h6>
            </div>
            <div class="hplan-body mb-40">
              <p>Ideal para servidores que estão em expansão</p>
              <div class="hplan-icon w-100">
                <ul class="mb-10 mt-10 space-y-2 text-left">
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Edite as cores da sua loja</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Produtos ilimitados</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Categorias ilimitadas</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Sub-usuários ilimitados</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Descontos ilimitados</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> MercadoPago, PayPal</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Entregas instantâneas</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Sem limite de faturamento</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Integração com Discord</li>
                </ul>
              </div>
            </div>
            <div class="hplan-btn">
              <a href="https://cliente.hcrp.store/register/basic" class="btn">Teste gratuitamente</a>
              <small class="d-block mt-2 font-weight-bold">O primeiro mês é por nossa conta!</small>
            </div>
          </div>
        </div>

        <div class="col-lg-4 col-md-4">
          <div class="single-hplan active text-center mb-30">
            <div class="hplan-head">
              <span>Completo</span>
              <h3>R$60 <small>/ mês</small></h3>
              <h6>Sem taxa sobre venda</h6>
            </div>
            <div class="hplan-body mb-40">
              <p>Para ajudar seu servidor a crescer e vender ainda mais</p>
              <div class="hplan-icon w-100">
                <ul class="mb-10 mt-10 space-y-2 text-left">
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Edite as cores da sua loja</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Produtos ilimitados</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Categorias ilimitadas</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Sub-usuários ilimitados</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Descontos ilimitados</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Estátisticas</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> MercadoPago, PayPal, PicPay, Pix e Boleto</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Entregas instantâneas</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Sem limite de faturamento</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Use seu próprio domínio</li>
                  <li class="text-sm"><i class="fa fa-fw fa-check"></i> Integração com Discord</li>
                </ul>
              </div>
            </div>
            <div class="hplan-btn">
              <a href="https://cliente.hcrp.store/register/full" class="btn">Teste gratuitamente</a>
              <small class="d-block mt-2 font-weight-bold">O primeiro mês é por nossa conta!</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Plans',
  props: [

  ]
}
</script>

<style scoped>

.single-hplan {
  background: #191919;
  border-bottom: 3px solid #191919;
  transition-duration: .2s;
  transition-timing-function: ease-in-out;
}
.single-hplan:hover {
  border-bottom: 3px solid #b622b5;
  background-image: linear-gradient(143deg, #b622b5 -24%, rgb(25, 25, 25) 20%);
}

</style>