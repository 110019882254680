<template>
  <div class="menu-area">
    <div class="main-menu text-right">
      <nav id="mobile-menu">
        <ul>
          <li>
            <a href="" @click.prevent="scrollToSection('home')">
              <i class="far fa-star mr-1"></i>
              Inicio
            </a>
          </li>
          <li v-if="showMenu">
            <a href="" @click.prevent="scrollToSection('plans')">
              <i class="fa fa-search-dollar mr-1"></i>
              Preços
            </a>
          </li>
          <li v-if="showMenu">
            <a href="" @click.prevent="scrollToSection('unlimited-features')">
              <i class="fa fa-cubes mr-1"></i>
              Recursos
            </a>
          </li>
          <li v-if="showMenu">
            <a href="" @click.prevent="scrollToSection('faq')">
              <i class="far fa-question-circle mr-1"></i>
              F.A.Q
            </a>
          </li>
          <li>
            <a href="https://discord.gg/vHaB4Y2SZA" target="_blank">
              <i class="far fa-comments mr-1"></i> Fale conosco
            </a>
          </li>
          <li>
            <a href="https://cliente.hcrp.store" target="_blank">
              <i class="far fa-user-circle mr-1"></i>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>

export default {
  name: 'NavbarPrimary',
  data () {
    return {
      windowWidth: window.innerWidth,
      showMenu: true,
    }
  },
  methods: {
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({
          behavior: 'smooth'
        });
      }
    },
    updateWidth() {
      this.windowWidth = window.innerWidth;
    }
  },
  mounted() {
    window.addEventListener('resize', this.updateWidth);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateWidth);
  },
  created() {
    this.showMenu = (this.windowWidth > 994)
  },
  watch: {
    windowWidth(newWidth) {
      this.showMenu = (newWidth > 994)
    }
  }
}
</script>

<style>


</style>